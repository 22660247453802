import styled from '@emotion/styled';
import React, { useState, useCallback, useEffect, useRef, memo } from 'react';

const ScrollProgress = styled.div`
  width: 100%;
  height: 20px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  -webkit-font-smoothing: antialiased;
`;

const ScrollProgressBar = styled.div`
  height: 100%;
  background-color: #282828;
  transition: transform 200ms ease 0s;
`;

const ProgressBar = memo((): JSX.Element => {
  const [width, setWidth] = useState<number>(0);
  const progressRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback((): void => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop === 0) {
      setWidth(0);
      return;
    }

    const windowHeight: number = scrollHeight - clientHeight;
    const currentPercent: number = scrollTop / windowHeight;

    setWidth(currentPercent * 100);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <ScrollProgress>
      <ScrollProgressBar style={{ width: width + '%' }} />
    </ScrollProgress>
  );
});

export default ProgressBar;
