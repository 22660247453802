import '../../font/font.css';
import React, { FunctionComponent } from 'react';
import { Global, css } from '@emotion/react';

const defaultStyle = css`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* color: #393a34; */

    ::selection {
      background-color: #e1e1e1;
      color: #595959;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  h1,
  h2 {
    color: #282828;
  }

  html,
  body,
  #___gatsby {
    margin: 0 auto;
    background-color: #f5f5f5;
    font-family: 'pretendard';
    /* color: rgb(25, 26, 32); */
    color: #282828;
  }

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #393a347a;
      padding: 10px;
      text-align: center;
    }
  }
`;

const GlobalStyle: FunctionComponent = function () {
  return <Global styles={defaultStyle} />;
};

export default GlobalStyle;
